import React, { useRef, useEffect, useContext } from "react";
import { DataContext } from "../../lib/store/DataProviders";
import { enums } from "../../lib/utils/constants";
import { GuessRow } from "../../games/Worpel/GuessRow";

function GuessTable({ guessRowType, columnNames }) {
  const GuessRowType = guessRowType || GuessRow;
  const stores = useContext(DataContext);
  const guesses = stores.usePersistentStore((state) => state.guesses);
  const [target, levelResult, players] = stores.useGameStore((state) => [
    state.target,
    state.levelResult,
    state.players,
  ]);

  const isLoading = guesses.length === 0 || target === null;

  const divRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      divRef.current &&
        divRef.current.scrollIntoView({
          behavior: "auto",
        });
    }, 0);
  });

  return (
    <>
      <div
        id="guess-table"
        className={`table-container container mx-auto max-w-4xl ${
          isLoading && "hidden"
        }`}
      >
        <div className="game-table -mx-4 overflow-hidden">
          <div
            className="game-table__head relative z-10 border-b-2 border-solid border-worpel-blue px-2.5
         shadow-[0_5px_30px_rgb(0_0_0_/_30%)] md:px-0"
          >
            <div className="game-table__row grid grid-cols-[0_60px_repeat(6,_minmax(0,_1fr))] text-center text-xs font-extrabold uppercase text-worpel-blue md:grid-cols-[250px_100px_repeat(6,_minmax(0,_1fr))]">
              {columnNames.map((label) => (
                <div key={label} className="game-table__cell">
                  {label}
                </div>
              ))}
            </div>
          </div>
          <div
            className="game-table__body max-h-[calc(var(--vh,1vh)*100_-_382px)] min-h-[160px]
          overflow-auto md:max-h-[calc(var(--vh,1vh)*100_-_526px)]"
          >
            {!isLoading &&
              guesses.map((player, index) => {
                return (
                  <GuessRowType
                    key={`${player.playerId}-${index}`}
                    player={players[player.playerId]}
                    scrollRef={index === guesses.length - 1 ? divRef : null}
                    attempt={index + 1}
                  />
                );
              })}
            {!isLoading && levelResult === enums.levelResult.LOST && (
              <GuessRowType player={target} scrollRef={divRef} attempt={9} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default GuessTable;
