import { trackSelect } from "../../lib/utils/analytics";
import ShareButton from "../ShareButton";
import SilhouetteImage from "../silhouette/SilhouetteImage";
import SilhouetteModal from "../silhouette/SilhouetteModal";
import { useContext } from "react";
import { DataContext } from "../../lib/store/DataProviders";
import { StreakFooter } from "./footers/StreakFooter";

function ResultButton(props) {
  const stores = useContext(DataContext);
  const [target, isOpen, setIsOpen] = stores.useGameStore((state) => [
    state.target,
    state.isResultOpen,
    state.setIsResultOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackSelect({
      contentType: "button",
      itemId: "show_answer",
    });
  }

  return (
    <>
      <button
        onClick={openModal}
        className="mt-1 mb-3.5 px-5 py-2 font-bold uppercase text-worpel-blue underline md:px-7 md:py-5"
      >
        Show Answer
      </button>

      <SilhouetteModal show={isOpen} onClose={closeModal}>
        <ModalBody target={target} footer={props.footer} />
      </SilhouetteModal>
    </>
  );
}

function ModalBody({ target, footer }) {
  return (
    <div className="mt-2 text-center text-worpel-blue">
      <SilhouetteImage reveal={true} />
      <div className="border-y border-solid border-worpel-blue px-2.5 pt-9 pb-8">
        <h3 className="md:text-xl">Sorry, the correct answer was</h3>
        <h2 className="my-2.5 text-xl font-extrabold uppercase leading-tight tracking-wide md:text-3xl">
          {`${target.givenName} ${target.surname}`}
        </h2>
        <h3 className="mb-7 md:text-xl">You can try again tomorrow!</h3>
        <div>
          <ShareButton />
        </div>
      </div>
      {footer || <StreakFooter />}
    </div>
  );
}

export default ResultButton;
