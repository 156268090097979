export function PlayFinalsFooter() {
  return (
    <a
      href="https://keeperfantasy.com/?utm_source=worpel&utm_medium=affiliate&utm_campaign=results"
      target="_blank"
      className=" -mb-4 mt-3 flex items-center justify-start rounded-sm pb-3 text-left sm:-mx-6 sm:mt-4 sm:px-6"
    >
      <img
        className="min-h-[3rem] md:min-h-[5.5rem]"
        src="/img/promo/keeper-strap.png"
      />
    </a>
  );
}
