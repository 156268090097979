import { enums } from "../utils/constants";
import create from "zustand";
import { legendsLevelNumber, legendsTargetIds } from "../utils/legendsLevels";
import { worpelLevelNumber, worpelTargetIds } from "../utils/worpelLevels";
import { worpelGuessesToResults } from "../../games/Worpel/utils/guessesToResults";
import { legendsGuessesToResults } from "../../games/Legends/utils/guessesToResults";

async function fetchPlayers(url) {
  const data = await (await fetch(url)).json();

  return data;
}

const useGameStore = create((set, get) => ({
  target: null,
  setTarget: (player) => {
    // console.log(`set target = ${player.surname}`);
    set({ target: player });
  },

  levelNumber: null,
  setLevelNumber: (number) => {
    console.log(`set levelNumber = ${number}`);
    set({ levelNumber: number });
  },

  imgUrl: null,
  setImgUrl: (url) => set({ imgUrl: url }),

  initError: false,
  setInitError: (value) => set({ initError: value }),

  players: [],
  fetchWorpelPlayers: async () => {
    try {
      console.log("fetching worpel players");
      const players = await fetchPlayers("json/worpel.json");
      set({ players: players });
    } catch (error) {
      console.log(error.message);
    }
  },
  fetchLegendsPlayers: async () => {
    try {
      console.log("fetching legends players");
      const players = await fetchPlayers("json/legends.json");
      set({ players: players });
    } catch (error) {
      console.log(error.message);
    }
  },

  game: null,
  setGame: (game) => set({ game: game }),

  analyticsGameName: "",
  setAnalyticsGameName: (name) => set({ analyticsGameName: name }),

  shareGameName: "",
  setShareGameName: (name) => set({ shareGameName: name }),

  shareGameUrl: "",
  setShareGameUrl: (url) => set({ shareGameUrl: url }),

  isSilhouetteOpen: false,
  setIsSilhouetteOpen: (isOpen) =>
    set((state) => ({ isSilhouetteOpen: isOpen })),

  isAnswerOpen: false,
  setIsAnswerOpen: (isOpen) => set((state) => ({ isAnswerOpen: isOpen })),

  isResultOpen: false,
  setIsResultOpen: (isOpen) => set((state) => ({ isResultOpen: isOpen })),

  levelResult: enums.levelResult.TBD,
  setLevelResult: (result) => set((state) => ({ levelResult: result })),

  setupWorpelLevel: () => {
    get().setGame(enums.game.WORPEL);
    get().setLevelNumber(worpelLevelNumber);
    get().setAnalyticsGameName("Worpel");
    get().setShareGameName("Worpel");
    get().setShareGameUrl("https://playworpel.com");

    const target = get().players[worpelTargetIds[worpelLevelNumber]];
    if (target) {
      get().setTarget(target);
      const imgUrl = `https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/2024014/${target.playerId.substr(
        4
      )}.png?i10c=img.resize(scale_height:0.6)`;
      get().setImgUrl(imgUrl);
      get().setInitError(false);
    } else {
      get().setInitError(true);
    }
  },

  setupLegendsLevel: () => {
    get().setGame(enums.game.LEGENDS);
    get().setLevelNumber(legendsLevelNumber);
    get().setAnalyticsGameName("Legends");
    get().setShareGameName("Legends");
    get().setShareGameUrl("https://playworpel.com/legends");

    //const target = players[Math.floor(Math.random() * players.length)];
    const target = get().players[legendsTargetIds[legendsLevelNumber]];
    if (target) {
      get().setTarget(target);
      const imgUrl = `https://s.afl.com.au/staticfile/AFL%20Tenant/AFL/Players/ChampIDImages/AFL/${target.lastSeason}014/${target.dataId}.png`;
      get().setImgUrl(imgUrl);
      get().setInitError(false);
    } else {
      get().setInitError(true);
    }
  },

  clearState: () => {
    console.log("clearing state");
    set((state) => ({
      target: null,
      players: [],
      isSilhouetteOpen: false,
      isAnswerOpen: false,
      isResultOpen: false,
      levelResult: enums.levelResult.TBD,
    }));
  },
}));

export default useGameStore;
